import CryptowarsImage from "../../img/games/cryptowars.webp";
import CritterChaosImage from "../../img/games/critterChaos.webp";
import PumpkinTalesImage from "../../img/games/pumpkinTales.webp";
import SkySwoopImage from "../../img/games/skySwoop.webp";
import TagCityTalesImage from "../../img/games/tagCityTales.webp";
import TropicalTriumphImage from "../../img/games/tropicalTriumph.webp";
import MaritimeMagicImage from "../../img/games/maritimeMagic.webp";
import TheFruitsGaloreImage from "../../img/games/theFruitsGalore.webp";

const gameData = (image, title, link, description) => {
    return {
        image,
        title,
        link,
        description
    }
}

export const data = [
    gameData(
        CryptowarsImage,
        "Cryptowars",
        "https://demo.games.profairgames.com/slot/cryptowars/",
        "Welcome to an exhilarating adventure into the world of cryptocurrency, where mining rigs work tirelessly, market bulls charge ahead, and bears lurk in the shadows with this 5x4, 1024ways slot from Profair Games."
    ),
    gameData(
        CritterChaosImage,
        "Critter Chaos",
        "https://demo.games.profairgames.com/slot/critter_chaos/",
        "Welcome to Critter Chaos, where monsters roam and mischief is the name of the game! Dive into a world where every spin could hatch wild wins or summon a chaotic critter. Look out for those enchanted Tomes, because three of them open the door to spellbinding free spins. Monster Eggs? They’re cracking with potential, trigger the bonus round and watch your fortune grow."
    ),
    gameData(
        PumpkinTalesImage,
        "Pumpkin Tales",
        "https://demo.games.profairgames.com/slot/pumpkin_tales/",
        "Welcome to Pumpkin Tales, a spook-tacular slot game where every spin could bring you a hauntingly big win! Brace yourself as you venture through a graveyard filled with grinning jack-o'-lanterns, ghoulish monsters, and shambling zombies. Will you trick your way to a treat, or will these spooky reels play a trick on you?"
    ),
    gameData(
        SkySwoopImage,
        "Sky Swoop",
        "https://demo.games.profairgames.com/instant/sky_swoop/",
        "Welcome to Sky Swoop, an exhilarating journey through the clouds where quick reflexes and precise timing are your best allies. As the intrepid whimsical character, you navigate through a series of ever-changing obstacles that stand between you and a big win."
    ),
    gameData(
        TagCityTalesImage,
        "Tag City Tales",
        "https://demo.games.profairgames.com/slot/tag_city_tales/",
        "Step into Tag City Tales, where the Neon colours of the graffiti will bring life to the drab urban background in this ProFair Games slot."
    ),
    gameData(
        TropicalTriumphImage,
        "Tropical Triumph",
        "https://demo.games.profairgames.com/slot/tropical_triumph/",
        "Welcome to Tropical Triumph, the ultimate slot game adventure set deep in the heart of an ancient Incan jungle! As you spin the reels, immerse yourself in the lush and vibrant wilderness, where exotic animals and tropical fruits abound. Venture into the jungle and discover the treasures that await in Tropical Triumph from Profair Games."
    ),
    gameData(
        MaritimeMagicImage,
        "Maritime Magic",
        "https://demo.games.profairgames.com/slot/maritime_magic/",
        "Welcome to Maritime Magic, where the wonders of the deep sea come to life with every spin! Descend into a vibrant underwater world teeming with life and mystery. Swim alongside schools of colorful fish, marvel at the grace of gliding rays, and uncover the hidden bonuses. Immerse yourself in the enchantment of the seabed, where every moment is a new adventure from Profair Games."
    ),
    gameData(
        TheFruitsGaloreImage,
        "The Fruits Galore",
        "https://demo.games.profairgames.com/slot/the_fruits_galore/",
        "Step back in time to the golden era of slot machines with our classic-themed game, where watermelons, cherries, lucky horseshoes, bells, and iconic bar symbols await your spins for a taste of timeless excitement in this 5x3 fixed line slot."
    ),
];